// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uyny0{display:grid;grid-auto-rows:48px;grid-template-columns:230px auto}.l_Uvt{padding:13px 16px;border-bottom:1px solid var(--wpp-grey-color-400)}.mIgab{align-items:center;padding:8px 16px;border-bottom:1px solid var(--wpp-grey-color-300)}`, "",{"version":3,"sources":["webpack://./src/pages/settings/integrations/components/statusMappingModal/StatusMappingModal.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,mBAAA,CACA,gCAAA,CAGF,OACE,iBAAA,CACA,iDAAA,CAGF,OACE,kBAAA,CACA,gBAAA,CACA,iDAAA","sourcesContent":[".grid {\n  display: grid;\n  grid-auto-rows: 48px;\n  grid-template-columns: 230px auto;\n}\n\n.gridHeader {\n  padding: 13px 16px;\n  border-bottom: 1px solid var(--wpp-grey-color-400);\n}\n\n.cell {\n  align-items: center;\n  padding: 8px 16px;\n  border-bottom: 1px solid var(--wpp-grey-color-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `uyny0`,
	"gridHeader": `l_Uvt`,
	"cell": `mIgab`
};
export default ___CSS_LOADER_EXPORT___;
