import { AnalyticsAction } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { AnalyticsTrackExternalLinkLaunchHandler, LAUNCH_LOCATIONS } from 'constants/analytics'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { simulateLinkClick } from 'utils/link'

interface Props {
  application: ApplicationItem
}

// TODO should be removed when core will release >6.0.0 version.
interface AnalyticsOverride {
  track: (data: AnalyticsAction) => void
  trackExternalLinkLaunch: AnalyticsTrackExternalLinkLaunchHandler
}

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation, analytics: originalAnalytics },
  } = useOs()

  const projectContext = useProject()
  const project = projectContext?.project
  const analytics = originalAnalytics as AnalyticsOverride

  const appLaunchErrors = useAppLaunchErrors(application)

  const handleOpenApplication = useCallback(async () => {
    const { name } = application

    if (!project) {
      console.warn(`Can't run "${name}" application, project is absent.`)
      return
    }

    const canRunApp = !appLaunchErrors.length
    if (!canRunApp) {
      console.warn(`Can't run "${name}" application, please check app configuration.`)
      return
    }

    if (application.type === NativeAppType.EXTERNAL_LINK) {
      analytics.trackExternalLinkLaunch({
        hierarchyNodeId: project.contextWorkspace ?? null,
        launchedFrom: LAUNCH_LOCATIONS.ProjectWorkflow,

        productName: application.externalAppName,
        productOwnerEmail: application.productOwnerEmail || '',
        productCommercialModel: application.commercialModel || '',
        productCategory: application.category || '',
        productSubcategory: application.subcategory || '',

        appId: application.externalAppId || '',
        appInstanceId: application.appInstanceId,
        appInstanceName: application.externalAppOriginName,
      })

      simulateLinkClick({ href: application.externalAppVersions[0].config.url!, target: '_blank' })
      return
    }

    navigation.openApp(application.appInstanceId, { analytics: { launchedFrom: 'Project workflow' } })
  }, [application, project, appLaunchErrors.length, navigation, analytics])

  return { handleOpenApplication }
}
