import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  showIntegrationDetailsModal,
  useIntegrationDetailsModal,
} from 'pages/settings/integrations/components/IntegrationDetailsModal'
import { DetailsModalType } from 'types/common/utils'
import { Integration } from 'types/integrations/integration'

interface QueryParams {
  view: string
  integrationId: string
}

export const useOpenIntegrationDetailsModal = (integrations: Integration[]) => {
  const { visible, hide } = useIntegrationDetailsModal()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const { view, integrationId } = Object.fromEntries(params.entries()) as unknown as QueryParams

    if (view && view === DetailsModalType.INTEGRATION_DETAILS) {
      const integration = integrations.find(({ id }) => id === integrationId)
      if (!integration) return

      showIntegrationDetailsModal({
        integrationId,
        name: integration.name,
        type: integration.type,
        onClose: setParams,
      })
    } else visible && hide()
  }, [hide, integrations, params, setParams, visible])
}
