import { WppTab, WppTabs, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { AttributesTab } from 'pages/settings/attributes/AttributesTab'
import { IntegrationsTab } from 'pages/settings/integrations/IntegrationsTab'
import styles from 'pages/settings/Settings.module.scss'
import { useSettingsTabs } from 'pages/settings/utils'
import { routesManager } from 'utils/routesManager'

export function Settings() {
  const { t } = useTranslation()
  const location = useLocation()
  const tabs = useSettingsTabs()

  const currentTab = matchPath(`${routesManager.settings.root.pattern}/:tab`, location.pathname)
  const defaultTab = routesManager.settings.attributes.shortPattern

  if (!currentTab) return <Navigate to={routesManager.settings.attributes.getURL()} replace />

  return (
    <Flex direction="column" className={styles.container}>
      <DashboardNavigation />

      <div className={styles.viewContainer}>
        <WppTypography type="3xl-heading">{t('project.settings.title')}</WppTypography>

        <WppTabs value={currentTab?.params.tab || defaultTab} className="wpp-spacing-24-top">
          {tabs.map(({ value, to, testId, label }) => (
            <Link key={value} to={to} tabIndex={-1} className="wpp-tab-styles">
              <WppTab value={value} data-testid={testId}>
                {label}
              </WppTab>
            </Link>
          ))}
        </WppTabs>

        <Routes>
          <Route path="/" element={<Navigate to={defaultTab} replace />} />
          <Route path={routesManager.settings.attributes.shortPattern} element={<AttributesTab />} />
          <Route path={routesManager.settings.integrations.shortPattern} element={<IntegrationsTab />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Flex>
  )
}
