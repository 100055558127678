import { projectApi } from 'api'
import { StatusMapping } from 'types/integrations/integration'

export interface SetStatusMappingsParams {
  workflowId: string
  statusMappings: Omit<StatusMapping, 'id'>[]
}

export const putStatusMappingsApi = ({ workflowId, statusMappings }: SetStatusMappingsParams) =>
  projectApi.put(`/wrike/workflows/${workflowId}/status-mappings`, { statusMappings })
