import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { useCreateWorkflowApi } from 'api/wrike/mutation/useCreateWorkflowApi'
import { useUpdateStatusMappingsApi } from 'api/wrike/mutation/useUpdateStatusMappingsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { queryClient } from 'providers/osQueryClient/utils'
import { WrikeWorkflow } from 'types/integrations/integration'
import { ProjectStatus } from 'types/projects/projects'
import { ApiError, CustomError } from 'utils/error'

export const statusMappingValidation = zod
  .object({
    osWorkflow: zod.record(zod.string(), zod.string()),
    wrikeWorkflow: zod.record(zod.string(), zod.string()),
  })
  .superRefine((data, ctx) => {
    Object.entries(data).forEach(([mappingKey, mappings]) => {
      Object.entries(mappings).forEach(([status, value]) => {
        if (!!value) return
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: 'Field is required',
          path: [mappingKey, status],
        })
      })
    })
  })

export const osStatuses = Object.values(ProjectStatus)

export interface FormValues {
  osWorkflow: Record<string, string>
  wrikeWorkflow: Record<string, string>
}

export const useMappingForm = (workflow: WrikeWorkflow) => {
  const defaultValues = useMemo(() => {
    const initialValues = {
      osWorkflow: Object.fromEntries(osStatuses.map(status => [status, ''])),
      wrikeWorkflow: Object.fromEntries(workflow.wrikeWorkflow.statuses.map(status => [status.id, ''])),
    }

    // apply saved values
    workflow.osWorkflow?.statusMappings.forEach(mapping => {
      if (mapping.direction === 'to_wrike') {
        initialValues.osWorkflow[mapping.osStatus] = mapping.wrikeStatusId
      } else {
        initialValues.wrikeWorkflow[mapping.wrikeStatusId] = mapping.osStatus
      }
    })

    return initialValues
  }, [workflow.osWorkflow?.statusMappings, workflow.wrikeWorkflow.statuses])

  return useForm<FormValues>({ defaultValues, validationSchema: statusMappingValidation })
}

export const useSaveForm = (workflow: WrikeWorkflow, onClose: () => void) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: updateStatuses } = useUpdateStatusMappingsApi()
  const { mutateAsync: createWorkflow } = useCreateWorkflowApi()

  const isNew = !workflow.osWorkflow?.id
  const wrikeStatusMap = Object.fromEntries(workflow.wrikeWorkflow.statuses.map(status => [status.id, status]))

  const saveForm = async (data: FormValues) => {
    try {
      const statusMappings = [
        ...Object.entries(data.osWorkflow)
          .filter(([, wrikeStatusId]) => !!wrikeStatusId)
          .map(([osStatus, wrikeStatusId]) => ({
            osStatus,
            wrikeStatusId,
            wrikeStatus: wrikeStatusMap[wrikeStatusId]?.name,
            wrikeStatusGroup: wrikeStatusMap[wrikeStatusId]?.group,
            direction: 'to_wrike',
          })),
        ...Object.entries(data.wrikeWorkflow)
          .filter(([, osStatus]) => !!osStatus)
          .map(([wrikeStatusId, osStatus]) => ({
            osStatus,
            wrikeStatusId,
            wrikeStatus: wrikeStatusMap[wrikeStatusId]?.name,
            wrikeStatusGroup: wrikeStatusMap[wrikeStatusId]?.group,
            direction: 'to_os',
          })),
      ]

      if (isNew) {
        await createWorkflow({
          workflowId: workflow.wrikeWorkflow.id,
          workflowName: workflow.wrikeWorkflow.name,
          statusMappings,
        })
      } else {
        await updateStatuses({
          workflowId: workflow.osWorkflow?.id!,
          statusMappings,
        })
      }

      await queryClient.invalidateQueries([ApiQueryKeys.INTEGRATION_BY_ID])
      await queryClient.invalidateQueries([ApiQueryKeys.INTEGRATIONS])
      onClose()
    } catch (e: any) {
      console.error(e)

      const errorDetails = e?.response?.data?.detail as ApiError
      if (errorDetails?.code === CustomError.WRIKE_WORKFLOW_MISSED_WRIKE_STATUSES) {
        showToast({ type: 'error', message: errorDetails.message })
        return
      }

      showToast({ type: 'error', message: t('common.generic_error')! })
    }
  }

  return saveForm
}
