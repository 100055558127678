import { memo } from 'react'

import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useAssignMember } from 'hooks/useAssignMember'
import { FluidEditableApplication } from 'pages/project/components/canvas/fluidCanvas/components/fluidApplication/FluidEditableApplication'
import { FluidPreviewApplication } from 'pages/project/components/canvas/fluidCanvas/components/fluidApplication/FluidPreviewApplication'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  isEditable?: boolean
  preview?: boolean
  templateView?: boolean
  activityId?: string
}

export const FluidApplication = memo(({ preview, application, templateView, activityId, isEditable }: Props) => {
  const assignMember = useAssignMember(application.assignUser)
  const showAction = isEditable
  const isDisabled = !showAction || templateView

  const { handleOpenApplication } = useOpenApplication({ application })

  return (
    <>
      {preview ? (
        <FluidPreviewApplication
          application={application}
          templateView={templateView}
          handleOpenApp={() => handleOpenApplication()}
          assignMember={assignMember}
          activityId={activityId}
        />
      ) : (
        <FluidEditableApplication
          application={application}
          handleOpenApp={() => handleOpenApplication()}
          isDisabled={isDisabled}
          assignMember={assignMember}
          showAction={showAction}
          activityId={activityId}
        />
      )}
    </>
  )
})
