import { projectApi } from 'api'
import { StatusMapping } from 'types/integrations/integration'

export interface CreateWorkflowParams {
  workflowId: string
  workflowName: string
  statusMappings: Omit<StatusMapping, 'id'>[]
}

export const postWorkflowApi = ({ workflowId, workflowName, statusMappings }: CreateWorkflowParams) =>
  projectApi.post('/wrike/workflows', {
    workflowId,
    workflowName,
    statusMappings,
  })
