import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useIntegrationsApi } from 'api/tenant/queries/useIntegrationsApi'
import { hasMappingError } from 'pages/settings/integrations/components/utils'
import { ProjectAttributeType } from 'types/projects/attributes'
import { routesManager } from 'utils/routesManager'

export const typeLabels: Record<ProjectAttributeType, string> = {
  TEXT_AREA: 'project.settings.attributes_fields.text_area',
  TEXT_INPUT: 'project.settings.attributes_fields.text_input',
  SINGLE_SELECT: 'project.settings.attributes_fields.single_select',
  MULTI_SELECT: 'project.settings.attributes_fields.multi_select',
  DATE_RANGE: 'project.settings.attributes_fields.date_range',
  HIERARCHY: 'project.settings.attributes_fields.hierarchy',
}

export const useSettingsTabs = () => {
  const { t } = useTranslation()
  const { data: integrations } = useIntegrationsApi({ staleTime: 60 * 1000 })
  const integrationAlerts = useMemo(() => integrations.filter(hasMappingError).length, [integrations])

  return useMemo(
    () => [
      {
        value: 'attributes',
        to: routesManager.settings.attributes.shortPattern,
        testId: 'settings-tab-attribute',
        label: t('project.settings.tabs.attributes'),
      },
      {
        value: 'integrations',
        to: routesManager.settings.integrations.shortPattern,
        testId: 'settings-tab-integration',
        label: t('project.settings.tabs.integrations', { count: integrationAlerts }),
      },
    ],
    [integrationAlerts, t],
  )
}
