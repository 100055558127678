import {
  WppCard,
  WppTypography,
  WppActionButton,
  WppIconGear,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { WrikeSettingsLogo } from 'components/svg/WrikeSettingsLogo'
import { hasMappingError } from 'pages/settings/integrations/components/utils'
import styles from 'pages/settings/integrations/Integrations.module.scss'
import { DetailsModalType } from 'types/common/utils'
import { Integration } from 'types/integrations/integration'
import { capitalizeFirstLetter } from 'utils/common'

interface IntegrationCardProps {
  integration: Integration
}

export const IntegrationCard = ({ integration }: IntegrationCardProps) => {
  const { t } = useTranslation()
  const [, setParams] = useSearchParams()

  const { type, id, name, activeForUser, totalWorkflowsCount, validWorkflowsCount } = integration

  const showMappingError = hasMappingError(integration)

  const handleOpenDetails = useCallback(
    (integrationId: string) => {
      setParams({ view: DetailsModalType.INTEGRATION_DETAILS, integrationId })
    },
    [setParams],
  )
  return (
    <WppCard className={styles.card} size="l">
      <Flex direction="column" gap={20}>
        <Flex justify="between">
          <WrikeSettingsLogo className={styles.logoWrapper} data-testid={`${type}-settings-logo`} />
          {activeForUser && (
            <WppActionButton
              className={styles.actionButton}
              variant="secondary"
              data-testid="open-integration-button"
              onClick={() => handleOpenDetails(id)}
            >
              <WppIconGear height={20} width={20} className={styles.greyColor800} />
            </WppActionButton>
          )}
        </Flex>
        <Flex direction="column" gap={4}>
          <WppTypography type="m-strong">{capitalizeFirstLetter(type)}</WppTypography>
          <WppTypography type="s-body" className={styles.greyColor800}>
            {name}
          </WppTypography>
        </Flex>

        {showMappingError && (
          <WppInlineMessage
            size="s"
            message={t('dashboard.settings.integration_card_mappings_warn', {
              mappedCount: validWorkflowsCount,
              count: totalWorkflowsCount,
            })}
            type="error"
          />
        )}
      </Flex>
    </WppCard>
  )
}
