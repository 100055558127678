import {
  WppButton,
  WppDivider,
  WppIconError,
  WppListItem,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { useFetchWrikeIntegrationWorkflowsApi } from 'api/tenant/queries/useFetchWrikeIntegrationWorkflowsApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { WrikeSettingsLogo } from 'components/svg/WrikeSettingsLogo'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { showStatusMappingModal } from 'pages/settings/integrations/components/statusMappingModal/StatusMappingModal'
import styles from 'pages/settings/integrations/Integrations.module.scss'
import { AppPermissions } from 'types/permissions/permissions'
import { IntegrationType } from 'types/projects/Integration'
import { getIntegrationsUrl } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

export enum PhaseTabsTypes {
  activities = 'activities',
  apps = 'apps',
}

export const phaseTabs = [
  { id: PhaseTabsTypes.activities, value: 'modals.phase_details.tabs.activities' },
  { id: PhaseTabsTypes.apps, value: 'modals.phase_details.tabs.apps' },
]

const integrationTypeIcons = {
  [IntegrationType.WRIKE]: WrikeSettingsLogo,
}

interface Props extends NiceModalWrappedProps {
  integrationId: string
  name: string
  type: IntegrationType
}

const IntegrationDetailsModal = ({ integrationId, name, type, isOpen, onCloseComplete, onClose }: Props) => {
  const {
    data: workflows,
    isLoading,
    isError,
    error,
  } = useFetchWrikeIntegrationWorkflowsApi({ params: { id: integrationId } })
  const { t } = useTranslation()

  const { isPermitted } = useIsPermitted()
  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const IconComponent = integrationTypeIcons[type]

  return (
    <SideModal open={isOpen} onWppSideModalClose={onClose} onWppSideModalCloseComplete={onCloseComplete} size="m">
      {isLoading ? (
        <SideModalContentSkeleton />
      ) : (
        <>
          <WppTypography slot="header" type="2xl-heading">
            {t('modals.integration_details.title')}
          </WppTypography>

          <Flex direction="column" slot="body" gap={24}>
            {isError ? (
              <ErrorState error={error} />
            ) : (
              <>
                <Flex align="center" gap={16}>
                  <IconComponent className={styles.logoWrapper} />
                  <Flex direction="column">
                    <WppTypography type="m-strong" className={styles.greyColor1000}>
                      {type}
                    </WppTypography>
                    <WppTypography type="s-body" className={styles.greyColor800}>
                      {name}
                    </WppTypography>
                  </Flex>
                </Flex>
                <WppDivider />
                <Flex direction="column" gap={8}>
                  <WppTypography slot="label" type="s-strong">
                    {t('modals.integration_details.title')}
                  </WppTypography>

                  <Flex gap={4} direction="column" className={styles.workspacesWrapper}>
                    {workflows?.map(workflow => (
                      <WppTooltip
                        key={workflow.wrikeWorkflow.id}
                        text={workflow.error?.message || t('modals.integration_details.mapping_needed')!}
                        config={{
                          trigger: workflow.error ? 'mouseenter' : 'manual',
                          hideOnClick: false,
                        }}
                        className={styles.tooltip}
                      >
                        <WppListItem
                          className={styles.workspaceItem}
                          isExtended
                          onClick={() => showStatusMappingModal({ workflow, onCloseAll: onClose })}
                        >
                          {!!workflow.error && <WppIconError slot="left" className={styles.warningIcon} />}

                          <WppTypography type="s-body" slot="label">
                            {workflow.wrikeWorkflow.name}
                          </WppTypography>
                          <span slot="caption">
                            {t('modals.integration_details.integration_projects', {
                              count: workflow.integrationsCount,
                            })}
                          </span>
                        </WppListItem>
                      </WppTooltip>
                    ))}
                  </Flex>
                </Flex>
              </>
            )}

            {isGlobalManage && (
              <>
                <WppDivider />
                <WppTypography type="s-body" className={styles.greyColor1000}>
                  <Trans
                    i18nKey="modals.integration_details.integration_missing_details" // Translation key
                    components={[
                      <></>, // Use an empty fragment for index 0
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={getIntegrationsUrl()} // URL
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline' }}
                      />,
                    ]}
                  />
                </WppTypography>
              </>
            )}
          </Flex>

          <Flex slot="actions" justify="end" gap={12}>
            <WppButton variant="primary" size="m" onClick={onClose}>
              {t('common.btn_done')}
            </WppButton>
          </Flex>
        </>
      )}
    </SideModal>
  )
}

export const { showModal: showIntegrationDetailsModal, useModal: useIntegrationDetailsModal } = createNiceModal<Props>(
  IntegrationDetailsModal,
  'integration-details-modal',
)
